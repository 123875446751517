import { takeLatest, call, all, put, select, take } from "redux-saga/effects";
import {
  auth,
  handleUserProfile,
  getCurrentUser,
  GoogleProvider,
  FacebookProvider,
  firestore,
} from "./../../database/utils";
import userTypes from "./user.types";
import {
  signInSuccess,
  signOutUserSuccess,
  resetPasswordSuccess,
  userError,
  emailVerificationSuccess,
  emailVerificationError,
  signInError,
  emailVerificationStart,
  removeUserSuccess,
  removeUserFailure,
  saveUserFailure,
  saveUserSuccess,
} from "./user.actions";
import {
  handleEmailVerificationAPI,
  handleResetPasswordAPI,
  handleUpdateBlockList,
  handleUpdateUserProfile,
  handleSaveUser,
  handleRemoveUser,
  handleUpdateUserLocation,
} from "./user.helpers";
import { Capacitor } from '@capacitor/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { GoogleAuthProvider } from "firebase/auth/web-extension";
import { signInWithCredential } from "firebase/auth";

// GET USER INFO
export function* getSnapshotFromUserAuth(user, additionalData = {}) {
  try {
    const userRef = yield call(handleUserProfile, {
      userAuth: user,
      additionalData,
    });
    const snapshot = yield userRef.get();
    const userData = {
      id: snapshot.id,
      fcmToken: "fcmToken",
      lastSeen: new Date(),
      ...snapshot.data(),
    };
    yield put(signInSuccess(userData));
    yield call(updateUserOnline, userData);
  } catch (err) {
    // console.error(err)
  }
}
// CHECK USER SESSION
export function* isUserAuthenticated() {
  try {
    const userAuth = yield getCurrentUser();
    if (!userAuth) return;
    yield getSnapshotFromUserAuth(userAuth);
  } catch (err) {
    // console.log(err);
  }
}
export function* onCheckUserSession() {
  yield takeLatest(userTypes.CHECK_USER_SESSION, isUserAuthenticated);
}
// EMAIL AUTH LOGIN
export function* emailSignIn({ payload: { email, password } }) {
  try {
    const { user } = yield auth.signInWithEmailAndPassword(email, password);
    yield getSnapshotFromUserAuth(user, { isSignIn: true });
  } catch (err) {
    if (err.code === "auth/wrong-password") {
      yield put(signInError(["Incorrect password"]));
    } else if (err.code === "auth/user-not-found") {
      yield put(signInError(["Email not found"]));
    } else if (err.code === "auth/too-many-requests") {
      yield put(
        signInError(["Too many requests, please try again in a few minutes"])
      );
    } else {
      yield put(signInError([err.message]));
    }
  }
}
export function* signInWithEmailAndPassword({ payload: { email, password } }) {
  try {
    const { user } = yield auth.signInWithEmailAndPassword(email, password);
    yield getSnapshotFromUserAuth(user);
  } catch (err) {
    yield put(signInError(err.message));
  }
}
export function* onEmailSignInStart() {
  yield takeLatest(userTypes.SIGN_IN_START, emailSignIn);
}
// AUTH REGISTER
export function* signUpUser({
  payload: {
    username,
    displayName,
    email,
    password,
    confirmPassword,
    photoURL,
  },
}) {
  if (password !== confirmPassword) {
    const err = ["passwords don't match"];
    yield put(userError(err));
    return;
  }
  try {
    const { user } = yield auth.createUserWithEmailAndPassword(email, password);
    const additionalData = {
      username,
      displayName,
      email,
      photoURL,
    };
    yield getSnapshotFromUserAuth(user, additionalData);
    yield put(emailVerificationStart({ email }));
  } catch (err) {
    yield put(userError([err.message]));
  }
}
export function* onSignUpUserStart() {
  yield takeLatest(userTypes.SIGN_UP_USER_START, signUpUser);
}

// GOOGLE AUTH LOGIN 
export function* googleSignIn() {
  try {
    const platform = Capacitor.getPlatform();

    if (platform === 'ios') {
      const codetrixUser = yield call(() => GoogleAuth.signIn());
      const { authentication } = codetrixUser;
      if (!authentication?.idToken) {
        throw new Error("Authentication failed: No ID token received.");
      }
      const idToken = authentication.idToken;
      const credential = GoogleAuthProvider.credential(idToken);
      const firebaseCredentialUser = yield call(() => signInWithCredential(auth, credential));
      const user = firebaseCredentialUser.user;
      yield getSnapshotFromUserAuth(user, { isSignIn: true});
    } else {
      // WEB GOOGLE AUTH
      const { user } = yield auth.signInWithPopup(GoogleProvider);
      yield getSnapshotFromUserAuth(user, { isSignIn: true });
    }
  } catch (error) {
    console.error("Google Sign-In Error:", error);
    yield put(signInError(error.message));
  }
}
export function* onGoogleSignInStart() {
  yield takeLatest(userTypes.GOOGLE_SIGN_IN_START, googleSignIn);
}
// FACEBOOK AUTH
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
export function* facebookSignIn() {
  try {
    // const { user } = yield auth.signInWithPopup(FacebookProvider);

    // // Ensure that user has essential details like email
    // if (!user.email) {
    //   throw new Error("Email is required but not provided by Facebook");
    // }

    // yield getSnapshotFromUserAuth(user, { isSignIn: true });
    const { user } = yield auth.signInWithPopup(FacebookProvider);

    if (isMobile) {
      // Use redirect for mobile devices
      yield auth.signInWithRedirect(FacebookProvider);
      yield getSnapshotFromUserAuth(user, { isSignIn: true });
    } else {
      // Use popup for non-mobile devices
      yield getSnapshotFromUserAuth(user, { isSignIn: true });
    }

    if (!user.isVerified && !user.emailVerificationSent) {
      yield call(handleEmailVerificationAPI);
      yield put(emailVerificationStart({ email: user.email }));

      yield put({
        type: userTypes.EMAIL_VERIFICATION_SENT,
        payload: true,
      });
    }
  } catch (err) {
    yield put(signInError([err.message]));
  }
}
export function* onFacebookSignInStart() {
  yield takeLatest(userTypes.FACEBOOK_SIGN_IN_START, facebookSignIn);
}
// RESET PASSWORD
export function* resetPassword({ payload: { email } }) {
  try {
    yield call(handleResetPasswordAPI, email);
    yield put(resetPasswordSuccess());
  } catch (err) {
    yield put(userError(err));
  }
}
export function* onResetPasswordStart() {
  yield takeLatest(userTypes.RESET_PASSWORD_START, resetPassword);
}
// SEND EMAIL VERIFICATION
export function* sendEmailVerificationSaga() {
  try {
    const currentUser = yield select((state) => state.user.currentUser);

    yield call(handleEmailVerificationAPI, currentUser.email);
    yield take(userTypes.EMAIL_VERIFICATION_SUCCESS);
    yield put(emailVerificationSuccess(true));
  } catch (error) {
    console.error("Error sending email verification:", error);
    yield put(emailVerificationError(error.message));
  }
}
export function* onEmailVerificationStart() {
  yield takeLatest(
    userTypes.EMAIL_VERIFICATION_START,
    sendEmailVerificationSaga
  );
}
// UPDATE EMAIL VERIFICATION STATUS
function* verifyEmail({ payload: oobCode }) {
  try {
    yield call([auth, auth.applyActionCode], oobCode);
    const user = auth.currentUser;
    if (user) {
      const userRef = firestore.collection("users").doc(user.uid);
      yield call([userRef, userRef.update], { isVerified: true });

      yield put(emailVerificationSuccess());
    }
  } catch (error) {
    yield put(emailVerificationError(error.message));
  }
}
export function* onVerifyEmailStart() {
  yield takeLatest(userTypes.VERIFY_EMAIL_START, verifyEmail);
}
//OLD UPDATE EMAIL VERIFICATION
export function* updateUserVerificationStatus() {
  yield put(emailVerificationSuccess(true));
}
export function* onEmailVerificationSuccess() {
  yield takeLatest(
    userTypes.EMAIL_VERIFICATION_SUCCESS,
    updateUserVerificationStatus
  );
}
//UPDATE lastSeen AND isOnline
export function* updateUserOffline() {
  try {
    const currentUser = yield select((state) => state.user.currentUser);
    const { id } = currentUser;
    const userRef = firestore.collection("users").doc(id);

    if (currentUser && currentUser.id) {
      yield call([userRef, userRef.update], {
        lastSeen: new Date(),
        isOnline: false,
        fcmToken: "fcmTokenOffline",
      });
    } else {
      // console.error("User does not have permission to update online status");
    }
  } catch (error) {
    // console.error("Error updating user online status:", error);
  }
}
export function* updateUserOnline() {
  try {
    const currentUser = yield select((state) => state.user.currentUser);
    const { id, lastSeen } = currentUser;
    const userRef = firestore.collection("users").doc(id);
    if (currentUser && currentUser.id) {
      yield call([userRef, userRef.update], {
        lastSeen,
        isOnline: true,
        fcmToken: "fcmTokenOnline",
      });
    } else {
      // console.error("User does not have permission to update online status");
    }
  } catch (error) {
    // console.error("Error updating user online status:", error);
  }
}
//SIGN OUT
export function* signOutUser() {
  try {
    const currentUser = yield select((state) => state.user.currentUser);

    if (currentUser && currentUser.id) {
      yield call(updateUserOffline, {
        isOnline: false,
        lastSeen: new Date(),
        fcmToken: "fcmTokenOut",
      });
      yield call([auth, auth.signOut]);
      yield put(signOutUserSuccess());
    } else {
      // console.error(
      //   "Cannot update online status: currentUser or id is missing"
      // );
    }
  } catch (err) {
    // console.error("Sign out error:", err);
  }
}
export function* onSignOutUserStart() {
  yield takeLatest(userTypes.SIGN_OUT_USER_START, signOutUser);
}
//UPDATE USER LOCATION
export function* updateUserLocation({ payload }) {
  try {
    yield call(handleUpdateUserLocation, payload);
    yield put({ type: userTypes.UPDATE_USER_LOCATION_SUCCESS, payload });
    // Success
  } catch (error) {
    yield put({ type: userTypes.UPDATE_USER_LOCATION_ERROR, error });
    // Error
  }
}
export function* onUpdateUserLocationStart() {
  yield takeLatest(userTypes.UPDATE_USER_LOCATION_START, updateUserLocation);
}
//UPDATE USER DETAILS
export function* updateUserProfile({ payload }) {
  try {
    yield call(handleUpdateUserProfile, payload);
    yield put({
      type: userTypes.UPDATE_USER_PROFILE_SUCCESS,
      payload: {
        ...payload,
        id: payload.id,
      },
    });
  } catch (error) {
    yield put({ type: userTypes.UPDATE_USER_PROFILE_ERROR, error });
  }
}
export function* onUpdateUserProfileStart() {
  yield takeLatest(userTypes.UPDATE_USER_PROFILE_START, updateUserProfile);
}
//ADD USER TO BLOCK LIST
export function* updateBlockList({ payload: { userID, targetID } }) {
  try {
    const updatedBlocked = yield call(handleUpdateBlockList, userID, targetID);
    yield put({
      type: userTypes.UPDATE_BLOCK_LIST_SUCCESS,
      payload: { userID, targetID, updatedBlocked },
    });
    // Success
  } catch (error) {
    yield put({ type: userTypes.UPDATE_BLOCK_LIST_ERROR, error });
    // Error
  }
}
export function* onUpdateBlockListStart() {
  yield takeLatest(userTypes.UPDATE_BLOCK_LIST_START, updateBlockList);
}
//FAVORITE USERS
function* saveUser({ payload }) {
  try {
    const userID = auth.currentUser.uid;
    yield handleSaveUser(userID, payload.favUserID);
    yield put(saveUserSuccess({ favUserID: payload.favUserID }));
  } catch (error) {
    yield put(saveUserFailure(error));
  }
}
export function* onSaveUserStart() {
  yield takeLatest(userTypes.SAVE_USER_START, saveUser);
}
function* removeUser({ payload }) {
  try {
    const userID = auth.currentUser.uid;
    const updatedSavedUsers = yield call(handleRemoveUser, userID, payload);
    yield put(removeUserSuccess(updatedSavedUsers));
    // yield put(fetchPostStart(payload));
  } catch (error) {
    yield put(removeUserFailure(error));
  }
}
export function* onRemoveUserStart() {
  yield takeLatest(userTypes.REMOVE_USER_START, removeUser);
}
export default function* userSagas() {
  yield all([
    call(onEmailSignInStart),
    call(onSignUpUserStart),
    call(onCheckUserSession),
    call(onResetPasswordStart),
    call(onGoogleSignInStart),
    call(onFacebookSignInStart),
    call(onSignOutUserStart),

    call(onVerifyEmailStart),

    call(onEmailVerificationStart),
    call(onEmailVerificationSuccess),

    call(onUpdateUserProfileStart),
    call(onUpdateUserLocationStart),
    call(onUpdateBlockListStart),

    call(onSaveUserStart),
    call(onRemoveUserStart),
  ]);
}
