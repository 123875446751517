import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import createSagaMiddle from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddle();
export const middlewares = [thunk, sagaMiddleware];

export const store = createStore(
  persistedReducer,
  applyMiddleware(...middlewares)
);
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

const storeConfig = {
  store,
  persistor,
  whitelist: ["user"],
};

export default storeConfig;
