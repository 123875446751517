import React, { useEffect, useState } from "react";
// import React, { useEffect, useState } from "react";
//DATABASE
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
//COMPONENTS
import Routing from "./Routing";
//NOTIFICATION
import { Geolocator } from "./components/Geolocator.jsx";
import { ModalAge } from "./components/Modal/Modals.jsx";
import { Button } from "./components/Button/Buttons.jsx";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { Keyboard } from "@capacitor/keyboard";
import { getDeviceType } from "./DeviceDetector.jsx";

const currentUserSelector = (state) => state.user.currentUser;
const mapState = createSelector([currentUserSelector], (currentUser) => ({
  currentUser,
}));

// export const Notifications = (props) => {
//   const [isTokenFound, setTokenFound] = useState(false);
//   const [token, setToken] = useState(null);

//   useEffect(() => {
//     async function fetchToken() {
//       const data = await getToken();
//       if (data) {
//         setToken(data);
//       }
//     }

//     fetchToken();
//   }, []);

//   useEffect(() => {
//     let data;
//     async function tokenFunc() {
//       data = await getToken(setTokenFound);
//       if (data) {
//         // console.log("Token is", data);
//       }
//       return data;
//     }

//     tokenFunc();
//   }, [setTokenFound]);

//   return <></>;
// };

function App() {
  const { currentUser } = useSelector(mapState);

  const isAgeVerified = localStorage.getItem("validAge") === "true";

  //MODAL
  const [hideModalAge, setHideModalAge] = useState(
    !isAgeVerified ? false : true
  );
  const toggleModalAge = () => setHideModalAge(!hideModalAge);
  const configModal = {
    hideModalAge,
    toggleModalAge,
  };

  const handleAgeCheck = () => {
    localStorage.setItem("validAge", "true");
    toggleModalAge();
  };

  useEffect(() => {
    GoogleAuth.initialize({
      clientId:
        "693628206599-7b0r9pif8mlhvglq5nbbg0st6jng5kkg.apps.googleusercontent.com",
      scopes: ["profile", "email"],
      grantOfflineAccess: true,
    });
  }, []);

  useEffect(() => {
    if (getDeviceType() === "iOS") {
      Keyboard.setResizeMode("body");
      Keyboard.setStyle({
        style: "dark",
      });

      const showKeyboard = () => {
        requestAnimationFrame(() => {
          document.body.classList.add("keyboard-open");
        });
      };

      const hideKeyboard = () => {
        requestAnimationFrame(() => {
          document.body.classList.remove("keyboard-open");
        });
      };

      Keyboard.addListener("keyboardWillShow", showKeyboard);
      Keyboard.addListener("keyboardWillHide", hideKeyboard);

      return () => {
        Keyboard.removeAllListeners();
      };
    }
  }, []);

  return (
    <div style={{ marginTop: "env(safe-area-inset-top)" }}>
      {currentUser && <Geolocator currentUser={currentUser} />}

      {getDeviceType() === "iOS"}

      <Routing />
      {/* <Notifications /> */}
      {!isAgeVerified && (
        <>
          {!currentUser && (
            <ModalAge {...configModal}>
              <div>
                <h3>
                  Welcome to <span className="logo">Lovers</span>
                </h3>
                <br />
                <p>
                  To access our platform you must be 18 years old or above.
                  Please select to continue.
                </p>
                <br />
                <Button
                  title="I'm over 18"
                  hierarchy="primary"
                  handleClick={handleAgeCheck}
                />
                <a href="https://google.com">
                  <Button title="I'm under 18" hierarchy="secondary" />
                </a>
              </div>
            </ModalAge>
          )}
        </>
      )}
    </div>
  );
}

export default App;
