import React, { useEffect } from "react";
//DATABASE
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
//COMPONENTS
import Routing from "./Routing";
//NOTIFICATION
import { Geolocator } from "./components/Geolocator.jsx";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { Keyboard } from "@capacitor/keyboard";
import { getDeviceType } from "./DeviceDetector.jsx";

const currentUserSelector = (state) => state.user.currentUser;
const mapState = createSelector([currentUserSelector], (currentUser) => ({
  currentUser,
}));

// export const Notifications = (props) => {
//   const [isTokenFound, setTokenFound] = useState(false);
//   const [token, setToken] = useState(null);

//   useEffect(() => {
//     async function fetchToken() {
//       const data = await getToken();
//       if (data) {
//         setToken(data);
//       }
//     }

//     fetchToken();
//   }, []);

//   useEffect(() => {
//     let data;
//     async function tokenFunc() {
//       data = await getToken(setTokenFound);
//       if (data) {
//         // console.log("Token is", data);
//       }
//       return data;
//     }

//     tokenFunc();
//   }, [setTokenFound]);

//   return <></>;
// };

function App() {
  const { currentUser } = useSelector(mapState);

  useEffect(() => {
    if (getDeviceType() === "iOS") {
      GoogleAuth.initialize({
        clientId:
          "693628206599-7b0r9pif8mlhvglq5nbbg0st6jng5kkg.apps.googleusercontent.com",
        scopes: ["profile", "email"],
        grantOfflineAccess: true,
      });
    }
  }, []);

  useEffect(() => {
    if (getDeviceType() === "iOS") {
      Keyboard.setResizeMode("body");
      Keyboard.setStyle({
        style: "dark",
      });

      const showKeyboard = () => {
        requestAnimationFrame(() => {
          document.body.classList.add("keyboard-open");
        });
      };

      const hideKeyboard = () => {
        requestAnimationFrame(() => {
          document.body.classList.remove("keyboard-open");
        });
      };

      Keyboard.addListener("keyboardWillShow", showKeyboard);
      Keyboard.addListener("keyboardWillHide", hideKeyboard);

      return () => {
        Keyboard.removeAllListeners();
      };
    }
  }, []);

  return (
    <div style={{ marginTop: "env(safe-area-inset-top)" }}>
      {currentUser && <Geolocator currentUser={currentUser} />}

      {getDeviceType() === "iOS"}

      <Routing />
      {/* <Notifications /> */}
    </div>
  );
}

export default App;
