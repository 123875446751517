import userTypes from "./user.types";

const INITIAL_STATE = {
  currentUser: null,
  resetPasswordSuccess: false,
  userErr: [],
  loading: false,
  emailVerificationSent: false,
  emailVerificationStatus: false,
  isVerified: false,
  isOnline: false,
  lastSeen: null,
  confirmationRequired: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...action.payload,
          isOnline: true,
          lastSeen: new Date(),
        },
        userErr: [],
      };
    case userTypes.SIGN_IN_ERROR:
      return {
        ...state,
        currentUser: null,
        userErr: [action.payload],
      };
    case userTypes.RESET_USER_STATE:
    case userTypes.SIGN_OUT_USER_SUCCESS:
      return {
        ...state,
        currentUser: null,  // Reset currentUser here
        ...INITIAL_STATE,
      };
      case userTypes.GOOGLE_SIGN_IN_ERROR:
  return {
    ...state,
    error: action.payload,
  };
  case userTypes.GOOGLE_SIGN_IN_SUCCESS:
  return {
    ...state,
    currentUser: {
      ...action.payload,
      isOnline: true,
      lastSeen: new Date(),
    },
    userErr: [],
  };
    case userTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case userTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: action.payload,
      };
    case userTypes.EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...action.payload,
          isVerified: action.payload,
          emailVerificationStatus: true,
        },
      };
    case userTypes.EMAIL_VERIFICATION_ERROR:
      return {
        ...state,
        emailVerificationStatus: false,
        userErr: action.payload,
      };
    case userTypes.EMAIL_VERIFICATION_SENT:
      return {
        ...state,
        emailVerificationSent: action.payload,
      };
    case userTypes.UPDATE_BLOCK_LIST_SUCCESS:
      if (state.currentUser && state.currentUser.id === action.payload.userID) {
        return {
          ...state,
          currentUser: {
            ...state.currentUser,
            blocked: action.payload.updatedBlocked,
          },
        };
      }
      return state;
    case userTypes.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      };
    case userTypes.UPDATE_USER_LOCATION_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      };
    case userTypes.SAVE_USER_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          favoriteUsers: [
            ...state.currentUser.favoriteUsers,
            action.payload.favUserID,
          ],
        },
      };
    case userTypes.REMOVE_USER_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          favoriteUsers: action.payload,
        },
      };

    default:
      return state;
  }
};

export default userReducer;
