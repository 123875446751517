import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/messaging";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();
// const messaging = firebase.messaging();

let currentToken = null;

let messaging;


// FACEBOOK PROVIDER
export const FacebookProvider = new firebase.auth.FacebookAuthProvider();
FacebookProvider.setCustomParameters({ prompt: "select_account" });

//GOOGLE PROVIDER
export const GoogleProvider = new firebase.auth.GoogleAuthProvider();
GoogleProvider.setCustomParameters({ prompt: "select_account" });

// RETRIEVE FCM TOKEN
// messaging
//   .getToken({ vapidKey: process.env.REACT_APP_VAPID_KEY })
//   .then((token) => {
//     currentToken = token;
//     if (token) {
//       handleUserProfile({
//         userAuth: auth.currentUser,
//         // additionalData: { fcmToken: token },
//       });
//     } else {
//       console.log(
//         "No registration token available. Request permission to generate one."
//       );
//     }
//   })
//   .catch((error) => {
//     console.log("An error occurred while retrieving token. ", error);
//   });

export { firestore, auth, storage, messaging };

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribeAuth = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const userRef = firestore.doc(`users/${userAuth.uid}`);
        const unsubscribeSnapshot = userRef.onSnapshot(
          (snapshot) => {
            if (snapshot.exists) {
              const userData = snapshot.data();
              resolve({ ...userAuth, ...userData });
            } else {
              resolve(null);
            }
          },
          (error) => {
            reject(error);
          }
        );

        const unsubscribeDeletion = userRef.onSnapshot(
          (snapshot) => {
            if (!snapshot.exists) {
              resolve(null);
            }
          },
          (error) => {
            reject(error);
          }
        );

        return () => {
          unsubscribeSnapshot();
          unsubscribeDeletion();
        };
      } else {
        resolve(null);
      }
    }, reject);
    return () => unsubscribeAuth();
  });
};
// DB PERSISTENCE
const firestoreSettings = {
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
  synchronizeTabs: true,
};
firestore.settings({
  ...firestoreSettings,
  merge: true,
});
try {
  firestore.FirestoreSettings.cache(firestoreSettings);
} catch (err) {
  if (err.code === "failed-precondition") {
    // console.log("Multiple tabs open")
  } else if (err.code === "unimplemented") {
    // console.log("Does not support persistence")
  }
}
//GENERATE USERNAME IF IT DOESNT SET
function generateUsername(displayName) {
  const firstName = displayName.split(" ")[0].toLowerCase();
  const randomChars = Math.random().toString(36).substring(2, 7);
  return `${firstName}${randomChars}`;
}
//CREATE USER PROFILE
export const handleUserProfile = async ({ userAuth, additionalData }) => {
  const { uid } = userAuth;
  const userRef = firestore.doc(`users/${uid}`);
  const snapshot = await userRef.get();
  if (!snapshot.exists) {
    //PESONAL DETAILS
    const { displayName, email, photoURL, username } = userAuth;
    let newUsername;
    if (userAuth.providerData[0].providerId === "google.com") {
      newUsername = generateUsername(displayName);
    } else if (userAuth.providerData[0].providerId === "facebook.com") {
      newUsername = generateUsername(displayName);
    } else {
      newUsername = username;
    }
    const gender = "";
    const birthDate = "";
    const sexualOrientation = "";
    const height = "";
    const weight = "";
    const bodyType = "";
    const ethnicity = "";
    //CONTACT
    const address = "";
    const biography = "";
    const phoneNumber = "";
    //STATUS
    const isOnline = false;
    const isBlocked = false;
    const isVerified = false;
    const isAvailable = true;
    //USER ROLES
    const userRoles = ["user"];
    //FAVORITE USERS
    const favoriteUsers = [];
    //GEOLOCATION
    const lat = 0;
    const lon = 0;
    //CREATED DATE
    const timestamp = new Date();
    //ACCOUNT PRIVACY
    const privacy = false;
    //FCM TOKEN
    const fcmToken = currentToken;
    //GET INVITE CODE FROM ROUTE FOR GOOGLE AUTH
    const inviteCode = window.location.pathname.split("/")[2] || "";
    const updatedInviteCode =
      userAuth.providerData[0].providerId === "google.com" && inviteCode
        ? inviteCode
        : "";
    try {
      await userRef.set({
        //PESONAL DETAILS
        displayName,
        username: newUsername || "",
        email,
        photoURL,
        birthDate,
        gender,
        sexualOrientation,
        height,
        weight,
        bodyType,
        ethnicity,
        //CONTACT
        address,
        phoneNumber,
        biography,
        //STATUS
        isOnline,
        isVerified,
        isBlocked,
        isAvailable,
        //USER ROLES
        userRoles,
        //FAVORITEUSERS
        favoriteUsers,
        //GEOLOCATION
        lat,
        lon,
        //INVITES
        inviteCode: updatedInviteCode,
        //PRIVACY
        privacy,
        createdDate: timestamp,
        fcmToken,
        ...additionalData,
      });
    } catch (err) {
      console.log(err);
    }
  }
  return userRef;
};

export const sendEmailVerification = () => {
  const actionCodeSettings = {
    url: "https://loversapp.co/verify-email",
    handleCodeInApp: true,
  };

  const user = auth.currentUser;
  if (user) {
    return user
      .sendEmailVerification(actionCodeSettings)
      .then(() => {
        console.log("Verification email sent.");
      })
      .catch((error) => {
        console.error("Error sending verification email:", error);
      });
  }
};
